<template>
  <div class="icon-container">
    <div class="icn">
      <a href="https://steamcommunity.com/id/miidorina/" target="_blank">
        <svg id="steam" class="single-icon" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg"><title>
          Steam</title>
          <path
              d="M11.979 0C5.678 0 .511 4.86.022 11.037l6.432 2.658c.545-.371 1.203-.59 1.912-.59.063 0 .125.004.188.006l2.861-4.142V8.91c0-2.495 2.028-4.524 4.524-4.524 2.494 0 4.524 2.031 4.524 4.527s-2.03 4.525-4.524 4.525h-.105l-4.076 2.911c0 .052.004.105.004.159 0 1.875-1.515 3.396-3.39 3.396-1.635 0-3.016-1.173-3.331-2.727L.436 15.27C1.862 20.307 6.486 24 11.979 24c6.627 0 11.999-5.373 11.999-12S18.605 0 11.979 0zM7.54 18.21l-1.473-.61c.262.543.714.999 1.314 1.25 1.297.539 2.793-.076 3.332-1.375.263-.63.264-1.319.005-1.949s-.75-1.121-1.377-1.383c-.624-.26-1.29-.249-1.878-.03l1.523.63c.956.4 1.409 1.5 1.009 2.455-.397.957-1.497 1.41-2.454 1.012H7.54zm11.415-9.303c0-1.662-1.353-3.015-3.015-3.015-1.665 0-3.015 1.353-3.015 3.015 0 1.665 1.35 3.015 3.015 3.015 1.663 0 3.015-1.35 3.015-3.015zm-5.273-.005c0-1.252 1.013-2.266 2.265-2.266 1.249 0 2.266 1.014 2.266 2.266 0 1.251-1.017 2.265-2.266 2.265-1.253 0-2.265-1.014-2.265-2.265z"/>
        </svg>
      </a>
    </div>

    <div class="icn">
      <a href="https://twitter.com/_midorina" target="_blank">
        <svg id="twitter" class="single-icon" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg"><title>
          Twitter</title>
          <path
              d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"/>
        </svg>
      </a>
    </div>

    <div class="icn">
      <a href="https://open.spotify.com/user/storedeath00?si=79407d1853fc4743" target="_blank">
        <svg id="spotify" class="single-icon" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg"><title>
          Spotify</title>
          <path
              d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
        </svg>
      </a>
    </div>

    <div class="icn">
      <a href="https://trakt.tv/users/midorina" target="_blank">
        <svg id="trakt" class="single-icon" viewBox="0 0 144.8 144.8" x="0px" xmlns="http://www.w3.org/2000/svg"
             y="0px">
          <title>Trakt</title>
          <g>
            <path d="M29.5,111.8c10.6,11.6,25.9,18.8,42.9,18.8c8.7,0,16.9-1.9,24.3-5.3L56.3,85L29.5,111.8z"/>
            <path d="M56.1,60.6L25.5,91.1L21.4,87l32.2-32.2h0l37.6-37.6c-5.9-2-12.2-3.1-18.8-3.1c-32.2,0-58.3,26.1-58.3,58.3
		c0,13.1,4.3,25.2,11.7,35l30.5-30.5l2.1,2l43.7,43.7c0.9-0.5,1.7-1,2.5-1.6L56.3,72.7L27,102l-4.1-4.1l33.4-33.4l2.1,2l51,50.9
		c0.8-0.6,1.5-1.3,2.2-1.9l-55-55L56.1,60.6z"/>
            <path d="M115.7,111.4c9.3-10.3,15-24,15-39c0-23.4-13.8-43.5-33.6-52.8L60.4,56.2L115.7,111.4z M74.5,66.8l-4.1-4.1l28.9-28.9
		l4.1,4.1L74.5,66.8z M101.9,27.1L68.6,60.4l-4.1-4.1L97.8,23L101.9,27.1z"/>
            <g>
              <g>
                <path d="M72.4,144.8C32.5,144.8,0,112.3,0,72.4C0,32.5,32.5,0,72.4,0s72.4,32.5,72.4,72.4C144.8,112.3,112.3,144.8,72.4,144.8z
M72.4,7.3C36.5,7.3,7.3,36.5,7.3,72.4s29.2,65.1,65.1,65.1s65.1-29.2,65.1-65.1S108.3,7.3,72.4,7.3z"/>
              </g>
            </g>
          </g>
        </svg>
      </a>
    </div>

    <div class="icn">
      <a href="https://www.facebook.com/yigit.egemen.07" target="_blank">
        <svg id="facebook" class="single-icon" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <title>Facebook</title>
          <path
              d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"/>
        </svg>
      </a>
    </div>

    <div class="icn">
      <a>
        <svg id="empty-icon" class="single-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"></svg>
      </a>
    </div>

    <div class="icn">
      <a href="https://www.linkedin.com/in/yigit-egemen/" target="_blank">
        <svg id="linkedin" class="single-icon" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg"><title>
          LinkedIn</title>
          <path
              d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
        </svg>
      </a>
    </div>

    <div class="icn">
      <a href="https://github.com/Midorina" target="_blank">
        <svg id="github" class="single-icon" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg"><title>
          GitHub</title>
          <path
              d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/>
        </svg>
      </a>
    </div>

    <div class="icn">
      <a href="mailto:midorina07@gmail.com">
        <svg id="mail" class="single-icon" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg"><title>
          E-mail</title>
          <path
              d="M24 4.5v15c0 .85-.65 1.5-1.5 1.5H21V7.387l-9 6.463-9-6.463V21H1.5C.649 21 0 20.35 0 19.5v-15c0-.425.162-.8.431-1.068C.7 3.16 1.076 3 1.5 3H2l10 7.25L22 3h.5c.425 0 .8.162 1.069.432.27.268.431.643.431 1.068z"/>
        </svg>
      </a>
    </div>

    <!--            <div class="icn">-->
    <!--                <a href="https://discord.gg/5RXauct" target="_blank">-->
    <!--                    <svg id="discord" class="single-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>A Discord server I own.</title><path d="M20.222 0c1.406 0 2.54 1.137 2.607 2.475V24l-2.677-2.273-1.47-1.338-1.604-1.398.67 2.205H3.71c-1.402 0-2.54-1.065-2.54-2.476V2.48C1.17 1.142 2.31.003 3.715.003h16.5L20.222 0zm-6.118 5.683h-.03l-.202.2c2.073.6 3.076 1.537 3.076 1.537-1.336-.668-2.54-1.002-3.744-1.137-.87-.135-1.74-.064-2.475 0h-.2c-.47 0-1.47.2-2.81.735-.467.203-.735.336-.735.336s1.002-1.002 3.21-1.537l-.135-.135s-1.672-.064-3.477 1.27c0 0-1.805 3.144-1.805 7.02 0 0 1 1.74 3.743 1.806 0 0 .4-.533.805-1.002-1.54-.468-2.14-1.404-2.14-1.404s.134.066.335.2h.06c.03 0 .044.015.06.03v.006c.016.016.03.03.06.03.33.136.66.27.93.4.466.202 1.065.403 1.8.536.93.135 1.996.2 3.21 0 .6-.135 1.2-.267 1.8-.535.39-.2.87-.4 1.397-.737 0 0-.6.936-2.205 1.404.33.466.795 1 .795 1 2.744-.06 3.81-1.8 3.87-1.726 0-3.87-1.815-7.02-1.815-7.02-1.635-1.214-3.165-1.26-3.435-1.26l.056-.02zm.168 4.413c.703 0 1.27.6 1.27 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334.002-.74.573-1.338 1.27-1.338zm-4.543 0c.7 0 1.266.6 1.266 1.335 0 .74-.57 1.34-1.27 1.34-.7 0-1.27-.6-1.27-1.334 0-.74.57-1.338 1.27-1.338z"/></svg>-->
    <!--                </a>-->
    <!--            </div>-->

    <!--            <div class="icn">-->
    <!--                <a href="https://www.instagram.com/midorina_7/" target="_blank">-->
    <!--                    <svg id="instagram" class="single-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>-->
    <!--                </a>-->
    <!--            </div>-->
  </div>
</template>

<script>
export default {
  name: "SocialConnections"
}
</script>

<style scoped>
@import '../styles/animations.css';

@keyframes pop {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.icon-container {
  display: flex;
  position: absolute;

  /*margin: 0 auto !important;*/
  /*width: 20em;*/
  bottom: 3vh;
  /*right: 30vw;*/
  /*align-items: flex-end !important;*/
  /*justify-content: flex-end !important;*/

  --social-connections-animation-delay: 30s
}

.single-icon {
  fill: #DCDCFFFF;
  margin: 0 2vw 0 2vw;
  width: calc(2vh + 2vw);
  visibility: hidden;
}

.icn {
  transform: translate(0em, 0%);
}

/* pop animations */
#steam {
  animation: calc(var(--social-connections-animation-delay) + 0.0s) pop;
  animation-fill-mode: forwards;
  visibility: hidden;
}

#twitter {
  animation: calc(var(--social-connections-animation-delay) + 0.5s) pop;
  animation-fill-mode: forwards;
  visibility: hidden;
}

#spotify {
  animation: calc(var(--social-connections-animation-delay) + 1.0s) pop;
  animation-fill-mode: forwards;
  visibility: hidden;
}

#trakt {
  animation: calc(var(--social-connections-animation-delay) + 1.5s) pop;
  animation-fill-mode: forwards;
  visibility: hidden;
}

#facebook {
  animation: calc(var(--social-connections-animation-delay) + 2.0s) pop;
  animation-fill-mode: forwards;
  visibility: hidden;
}

#linkedin {
  animation: calc(var(--social-connections-animation-delay) + 2.5s) pop;
  animation-fill-mode: forwards;
  visibility: hidden;
}

#github {
  animation: calc(var(--social-connections-animation-delay) + 3.0s) pop;
  animation-fill-mode: forwards;
  visibility: hidden;
}

#mail {
  animation: calc(var(--social-connections-animation-delay) + 3.5s) pop;
  animation-fill-mode: forwards;
  visibility: hidden;
}

/* hover animations */
#mail:hover {
  transition: fill 0.25s ease;
  fill: #D14836;
}

#github:hover {
  transition: fill 0.25s ease;
  fill: #181717;
}

#discord:hover {
  transition: fill 0.25s ease;
  fill: #7289DA;
}

#steam:hover {
  transition: fill 0.25s ease;
  fill: #000000;
}

#facebook:hover {
  transition: fill 0.25s ease;
  fill: #1877F2;
}

#twitter:hover {
  transition: fill 0.25s ease;
  fill: #1da1f2;
}

#trakt:hover {
  transition: fill 0.25s ease;
  fill: #ed1c24;
}

#instagram:hover {
  transition: fill 0.25s ease;
  fill: #E4405F;
}

#spotify:hover {
  transition: fill 0.25s ease;
  fill: #1ED760;
}

#linkedin:hover {
  transition: fill 0.25s ease;
  fill: #0a66c2;
}
</style>

<template>
  <StrangeGame/>
</template>

<script>
import StrangeGame from "@/components/StrangeGame";

export default {
  name: 'App',
  components: {StrangeGame}
}
</script>

<style>

</style>
